import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"

const BlogList = ({elements, pageInfo, category}) => {

  let basicPath = "/blog";
  if(category){
    basicPath = category.slug;
  }

  const items = [];

  for (let i=0; i< pageInfo.pageCount; i++) {
    if(i===0){
      items.push( <Link key={basicPath +i} activeClassName="active" to={basicPath}>
        {i+1}
      </Link>)
    }else{
      items.push( <Link key={basicPath +i} activeClassName="active" to={basicPath + "-" + (i+1)}>
        {i+1}
      </Link>)
    }
  }

  return (
    <section className="blog-one blog-page">
      <div className="container">
        <div className="row">
          {elements.map(blogDetail=>(
            <div key={blogDetail.slug+"/"} className="col-lg-4">
              <div className="blog-one__single">
                <div className="blog-one__image">
                  <Img fluid={{ ...blogDetail.image.sharp.fluid, aspectRatio: 11 / 9 }} alt={blogDetail.title} />
                  <Link to={blogDetail.slug+"/"} className="blog-one__plus">
                    <i className="kipso-icon-plus-symbol"></i>
                  </Link>
                </div>
                <div className="blog-one__content text-center">
                  <h2 className="blog-one__title">
                    <Link to={blogDetail.slug+"/"}>
                      {blogDetail.title}
                    </Link>
                  </h2>
                  <p className="blog-one__text blog-one__resume text-justify">
                    {blogDetail.shortdescription}
                  </p>
                  <Link to={blogDetail.slug+"/"} className="blog-one__link">
                    Leer más
                  </Link>
                </div>
              </div>
            </div>
          ))}

        </div>
        <div className="post-pagination">
          <Link to={basicPath}>
            <i className="fa fa-angle-double-left"></i>
          </Link>
          {items}
          {
            pageInfo.pageCount ===1 ? (
            <Link to={basicPath+"/"}>
              <i className="fa fa-angle-double-right"></i>
            </Link>
          ):
            <Link to={basicPath + "-" + pageInfo.pageCount+"/"}>
              <i className="fa fa-angle-double-right"></i>
            </Link>
          }
        </div>
      </div>
    </section>
  );
};

export default BlogList;
