import React from 'react';
import Layout from "../components/layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Nav from "../components/Nav"
import BlogList from "../components/BlogList"
import { graphql } from "gatsby"

export const query = graphql`  
    query($limit:Int!, $skip:Int!){
        allStrapiBlogArticle(limit: $limit, skip: $skip, sort: {fields: updated_at, order: DESC}) {
            totalCount
            nodes {
                id
                shortdescription
                title
                slug
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                itemCount
                pageCount
                perPage
            }
        }
    }
`
const BlogListPage = ({data}) => {
  let currentPage = data.allStrapiBlogArticle.pageInfo.currentPage;
  let slug="";
  if(currentPage == 1){
    slug="/blog/"
  }else {
    slug="/blog-" + currentPage +"/"
  }

  return (
    <Layout
      title="Rolteca | Blog"
      description="Novedades, reviews y recomendaciones sobre juegos de rol y de mesa. No te pierdas ninguna novedad "
      slug={slug}>
      <Nav />
      <PageHeader title="Blog, novedades y opinión" />
      <BlogList
        elements={data.allStrapiBlogArticle.nodes}
        pageInfo={data.allStrapiBlogArticle.pageInfo}
      />
      <Footer />
    </Layout>
  );
};

export default BlogListPage;
